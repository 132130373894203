import React, {Component} from 'react';

class Footer extends Component {

    renderNetworks() {
        return this.props.data
            ? this.props.data.social.map((s, i) => {
                return <li key={s.name + i}>
                        <a href={s.url}>
                            <img height={40} width={40} src={s.logo} alt={s.name}></img>
                        </a>
                    </li>
            })
            : '';
    }

    render() {
        return (
            <footer>
                <div className="row">
                    <div className="twelve columns">
                        <ul className="social-links">
                            {this.renderNetworks()}
                        </ul>
                        <ul className="copyright">
                            <li>&copy; Copyright {new Date().getFullYear()} garbacik.dev</li>
                        </ul>
                    </div>
                    <div id="go-top">
                        <a className="smoothscroll" title="Back to Top" href="#home">
                            <i className="fa fa-angle-up"></i>
                        </a>
                    </div>
                </div>
            </footer>
        );
    }
}

export default Footer;
