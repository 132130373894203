import React, { Component } from 'react';

class Header extends Component {

    renderSocials() {
        return this.props.data
            ? this.props.data.social.map((s) => {
                return <li key={s.name}>
                    <a href={s.url}>
                        <img src={s.logo} alt={s.name} height={40} width={40}></img>
                    </a>
                </li>
            })
            : '';
    }

    render() {

        let name;
        if (this.props.data) {
            name = this.props.data.name;
        }

        return (
            <header id="home">
                <nav id="nav-wrap">
                    <a className="mobile-btn" href="#nav-wrap" title="Show navigation">Show navigation</a>
                    <a className="mobile-btn" href="#home" title="Hide navigation">Hide navigation</a>

                    <ul id="nav" className="nav">
                        <li className="current"><a className="smoothscroll" href="#home">Home</a></li>
                        <li><a className="smoothscroll" href="#about">About</a></li>
                        <li><a className="smoothscroll" href="#resume">Resume</a></li>
                        <li><a className="smoothscroll" href="#work">Work</a></li>
                        <li><a className="smoothscroll" href="#skills">Skills</a></li>
                        <li><a className="smoothscroll" href="#certs">Certificates</a></li>
                        <li><a className="smoothscroll" href="#contact">Contact</a></li>
                    </ul>

                </nav>

                <div className="row banner">
                    <div className="banner-text">
                        <h1 className="responsive-headline">I'm {name}.</h1>
                        <hr />
                        <ul className="social" id="contact">
                            {this.renderSocials()}
                        </ul>
                    </div>
                </div>
                <p className="scrolldown">
                    <a className="smoothscroll" href="#about"><i className="icon-down-circle"></i></a>
                </p>
            </header>
        );
    }
}

export default Header;
